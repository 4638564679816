import { useEffect, useState } from "react";
import "./LandingPage.css";
import Logo from "../../assets/branding-image/aigallery_white.svg";

import UserAccount from "../UserAccount";
import { localStorageSetItem } from "../../utils/Storage/storageUtils";

const Header = (props) => {
  const navigateToStudio = () => {
    localStorageSetItem("user_persona", "Creator");
    localStorageSetItem("create_new_app", true);
    window.open("/studio", "_blank");
  };

  return (
    <div className="authenticateHeader">
      <div className="logo-wrap">
        <img
          src={Logo}
          alt="canvas logo"
        />
      </div>
      <div className="tabs-section">
        <ul className="inner-tabs">
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 0 ? " active" : ""}`}
            onClick={() => props.setTab(0)}>
            Home
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 1 ? " active" : ""}`}
            onClick={() => props.setTab(1)}>
            Gallery
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 2 ? " active" : ""}`}
            onClick={() => props.setTab(2)}>
            Discover
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 3 ? " active" : ""}`}
            onClick={() => props.setTab(3)}
          >
            Lab
          </li>
        </ul>
      </div>
      <div className="headerRightSection">
        <button className="headerRightSection-text" onClick={navigateToStudio}>
          <span>Create App</span>
        </button>

        <div className="headerRightSection-icon">
          <UserAccount />
        </div>
      </div>
    </div>
  );
};

export default Header;
