import { Grid, Typography, CardContent, Card } from "@mui/material";
import "./card.css";
import GalleryIcon1 from "../../assets/images/GalleryIcon1.svg";
import GalleryIcon2 from "../../assets/images/GalleryIcon2.svg";
import GalleryIcon3 from "../../assets/images/GalleryIcon3.svg";
import GalleryIcon4 from "../../assets/images/GalleryIcon4.svg";
import GalleryIcon5 from "../../assets/images/GalleryIcon5.svg";
import GalleryIcon6 from "../../assets/images/GalleryIcon6.svg";
import GalleryIcon7 from "../../assets/images/GalleryIcon7.svg";
import GalleryIcon8 from "../../assets/images/GalleryIcon8.svg";
import clapIconFilled from "../../assets/images/clap-icon-filled.svg";
import clapIconBlank from "../../assets/images/clap-icon-blank.svg";
import { useEffect, useState } from "react";
import AppModal from "../common/Modal/AppModal";
import { updateAppClap } from "../../Services/landing.service";
import APP_CONST from "../../Constants/app.constant";

const AppsCard = (props) => {
  const { data, index } = props;

  const [showAppModal, setShowAppModal] = useState(false);
  const [clapCount, setClapCount] = useState(0);
  const [isClapped, setIsClapped] = useState(false);

  useEffect(() => {
    upvotedApp();
  },[])

  const randomColor = (colorIndex) => {
    switch (colorIndex) {
      case 0:
        return "colorCodeOne";
      case 1:
        return "colorCodeTwo";
      case 2:
        return "colorCodeThree";
      case 3:
        return "colorCodeFour";
      case 4:
        return "colorCodeFive";
      case 5:
        return "colorCodeSix";
      case 6:
        return "colorCodeSeven";
      case 7:
        return "colorCodeEight";
      default:
        break;
    }
  };

  const getGalleryIcon = (index) => {
    switch (index) {
      case 0:
        return GalleryIcon1;
      case 1:
        return GalleryIcon2;
      case 2:
        return GalleryIcon3;
      case 3:
        return GalleryIcon4;
      case 4:
        return GalleryIcon5;
      case 5:
        return GalleryIcon6;
      case 6:
        return GalleryIcon7;
      case 7:
        return GalleryIcon8;
      default:
        break;
    }
  };

  const toggleAddModal = (e) => {
    if (e?.target?.className?.includes(APP_CONST.CLAP_ICON_IMAGE)) {
      return
    }
    setShowAppModal(true);
  };

  const updateClap = () => {
    const payload = {
      userId: localStorage.getItem("authServerId"),
      appId: data._id,
    };
    updateAppClap(payload)
      .then((response) => {
        setClapCount(response?.data?.clapCount);
        setIsClapped((prevState) => !prevState);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const upvotedApp = () => {
    const clapped = data.clappedBy.includes(localStorage.getItem("authServerId"));
    setIsClapped(clapped);
  }

  const getClapIcon = () => {
    return isClapped ? clapIconFilled: clapIconBlank;
  }

  return (
    <>
      <Grid
        item
        xl={3}
        xs={12}
        md={6}
        className="card-container"
        onClick={(e)=>toggleAddModal(e)}
      >
        <Card className={`styledCard ${randomColor(index % 8)}`}>
          <CardContent className="styledCardContent">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8} className="galleryContainerGrid">
                    <div className="galleryUserIcon">
                      <span>
                        {`${
                          data.appLabel?.length ? data.appLabel : data.name
                        }`.substring(0, 2)}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="apps-icon">
                      <img src={getGalleryIcon(index % 8)} alt="" />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className="title-bar"
                    >
                      {data.appLabel?.length ? data.appLabel : data.name}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      variant="body2"
                      fontFamily={"inter-regular"}
                      className="description-navapps"
                    >
                      {data.description}
                    </Typography>
                  </Grid>
                  <div className="clap-icon">
                    <img className="clap-icon-image" src={getClapIcon()} onClick={() => updateClap()} />
                    <span>{clapCount?clapCount:data.clapCount}</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {showAppModal && (
        <AppModal
          toggleAddModal={toggleAddModal}
          showAppModal={showAppModal}
          setShowAppModal={setShowAppModal}
          data={props}
          backgroundColor={randomColor(index % 8)}
          icon={getGalleryIcon(index % 8)}
          clapIcon={getClapIcon()}
          clapCount={clapCount ? clapCount : data.clapCount}
          updateClap={updateClap}
        />
      )}
    </>
  );
};
export default AppsCard;