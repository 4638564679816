import { Box, Button, InputBase, Menu, styled } from "@mui/material";
const shouldForwardPropIsMdDown = (prop) => prop !== "isMdDown";
export const StyledBox = styled(Box)(() => ({
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundColor: "#EFEFEF",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  flexDirection: "column",
  fontFamily: "Inter",
}));

export const StyledBoxButtonInput = styled(Box)(() => ({
  width: "100%",
}));

export const StyledBoxHeader = styled(Box)(() => ({
  position: "fixed",
  width: "100%",
  flex: 1,
}));

export const StyledInputBase = styled(InputBase, {
  shouldForwardProp: shouldForwardPropIsMdDown,
})(({ isMdDown }) => ({
  backgroundColor: "#FFFFFF",
  opacity: 1,
  borderRadius: 21,
  height: 40,
  width: isMdDown ? "100%" : 312,
  color: "#333333",
  fontSize: isMdDown ? 16 : "1.4em",
  marginRight: "5px",
}));

export const StyledSearchBox = styled(Box, {
  shouldForwardProp: shouldForwardPropIsMdDown,
})(({ isMdDown }) => ({
  backgroundColor: "black",
  borderRadius: 50,
  height: isMdDown ? 44 : 65,
  width: isMdDown ? "100%" : 760,
  color: "#333333",
  display: "none",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  marginTop: isMdDown ? "10px" : "0px",
  paddingLeft: isMdDown ? "0px" : "10px",
  marginLeft: isMdDown ? "0px" : "5px",
}));

export const StyledButtonDomain = styled(Button, {
  shouldForwardProp: shouldForwardPropIsMdDown,
})(({ isMdDown }) => ({
  borderRadius: 25,
  background: "#333333",
  color: "#fff",
  height: isMdDown ? "30px" : "45px",
  fontSize: isMdDown ? "15px" : "18px",
  width: isMdDown ? "200px" : "230px",
  marginLeft: isMdDown ? "0px" : "10px",
  marginRight: isMdDown ? "5px" : "10px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#414141",
    color: "#fff",
  },
}));

export const StyledButtonCategory = styled(Button, {
  shouldForwardProp: shouldForwardPropIsMdDown,
})(({ isMdDown }) => ({
  borderRadius: 25,
  background: "#C6E814",
  color: "#333333",
  height: isMdDown ? "30px" : "45px",
  width: isMdDown ? "252px" : "270px",
  marginLeft: isMdDown ? "5px" : "10px",
  marginRight: isMdDown ? "0px" : "10px",
  fontSize: isMdDown ? "15px" : "18px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#d1f610",
    color: "#333",
  },
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  spacious: {
    padding: 10,
  },
});
