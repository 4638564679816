import { API } from "./base.service";
import {
  USER,
  NAVIGATOR_APPS,
  AUTH_USER_ID,
  CHAT_SERVICE,
  MARKED_FLAG,
  CLAP,
} from "../Constants/api.constant";
import { localStorageGetItem } from "../utils/Storage/storageUtils";

export const getAllNavigatorApps = function () {
  return API.get(
    `${CHAT_SERVICE}${USER}${NAVIGATOR_APPS}${AUTH_USER_ID}${localStorageGetItem(
      "authServerId"
    )}`
  );
};

export const updateAppClap = (payload) => {
  return API.post(`${CHAT_SERVICE}${USER}${CLAP}`, payload);
};