import React, { useState } from "react";
import "./Sidebar.css";
import TechDemoIcon from "../../assets/images/TechDemoIcon.svg";
import CodeIcon from "../../assets/images/CodeAppSidebarIcon.svg";
import AllAppsIcon from "../../assets/images/allappSidebarIcon.svg"
import SearchIcon from "../../assets/images/searchSidebarIcon.svg"
import ChatIcon from "../../assets/images/chatSidebarIcon.svg"
import CreativeIcon from "../../assets/images/creativeSidebarIcon.svg"
import DocumentIcon from "../../assets/images/documentSidebarIcon.svg"
import SpotlightIcon from "../../assets/images/spotlightSidebarIcon.svg";
import DashboardGallery from "../../assets/images/dashboardGallery.svg";

const Sidebar = (props) => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const handleItemClick = (category, Index) => {
    setCategoryIndex(Index);
    props.setCategoryName(category);
  };
  const siderBarList = [
    { index: 0, title: "Spotlight", icon: SpotlightIcon },
    { index: 1, title: "Chat", icon: ChatIcon },
    { index: 2, title: "Search based", icon: SearchIcon },
    { index: 3, title: "Document", icon: DocumentIcon },
    { index: 4, title: "Code", icon: CodeIcon },
    { index: 5, title: "Creative", icon: CreativeIcon },
    { index: 6, title: "All Apps", icon: AllAppsIcon },
  ];

  const renderSideBarData = () => {
    return siderBarList.map((item) => (
      <li
        key={item.index}
        className={`nav-items ${categoryIndex === item.index ? "active" : ""}`}
        onClick={() => handleItemClick(item.title, item.index)}
      >
        <div className="bar-img-wrap">
          <img src={item.icon} alt={item.title} />
        </div>
        <span className="side-tooltip">{item.title}</span>
      </li>
    ));
  };

  return (
    <>
      <div className="sidebar-wrap">
        <div className="sidebar">
          <ul className="nav-list">{renderSideBarData()}</ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;