import React from "react";
import { useEffect, useState } from "react";
import { getAllNavigatorApps } from "../../Services/discover.service";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@mui/material";
import Loader from "../Loader";
import { Search } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import TableCell from "@mui/material/TableCell";
import "./Discovery.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import clapIconFilled from "../../assets/images/clap-icon-filled-whitebordered.svg";
import APP_CONST from "../../Constants/app.constant";
import moment from "moment";

function Discovery() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = "Canvas AI - Gallery";
    getAllApps();
  }, []);

  useEffect(() => {
    setSearchQuery("");
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const getAllApps = async () => {
    setApiLoader(true);
    setIsLoading(true);
    getAllNavigatorApps()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setTableData(res.data);
        }
        setIsLoading(false);
        setApiLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setApiLoader(false);
      });
  };

  const uniqueTags = (data) => {
    return [...new Set(data)];
  };

  const columns = [
    {
      name: "name",
      label: "App Name",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "userName",
      label: "Assigner",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "why",
      label: "Why",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "how",
      label: "How",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "credits",
      label: "Credits",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "kitName",
      label: "Kit",
      options: {
        display: false,
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "tag",
      label: "Tag(s)",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: { textAlign: "left", fontWeight: "bold" },
        }),
        sortCompare:
          (order) =>
          ({ data: tag1 }, { data: tag2 }) =>
            (tag1.length - tag2.length) * (order === "asc" ? 1 : -1),
        customBodyRender: (tags) =>
          uniqueTags(tags).map((tag, index) => (
            <>
              <span className="folder-path-column-wrap discovery-tag "> {tag}{index+1<uniqueTags(tags).length?",":""} </span>
            </>
          )),
      },
    },
    {
      name: "clapCount",
      label: "Clap",

      options: {
        filter: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index}>
              <img src={clapIconFilled} alt="Clap" />
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap clap-width">{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Created On",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <div className="folder-path-column-wrap">
                {moment.utc(moment(value).utc()).format("DD/MM/YYYY")}
              </div>
            </>
          );
        },
      },
    },
  ];

  const getMuiThemeUsage = () =>
    createTheme({
      typography: {
        useNextVariants: true,
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              padding: "10px",
              borderRadius: "38px",
              boxShadow: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            fixedHeader: {
              fontWeight: "700",
              fontSize: "15px",
              fontFamily: "inter-medium",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              "&:focus": {
                borderRadius: "20px",
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "transparent",
              color: "#FFF",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: "transparent",
              borderBottom: "#818181 2px solid",
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              paddingLeft: "0px",
              paddingRight: "0px",
              borderRadius: "0px",
            },
          },
        },
      },
      overrides: {
        MuiTableCell: {
          head: {
            backgroundColor: "transparent",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: apiLoader ? "0px !important" : "4px 24px 4px 15px",
          },
        },
        MUIDataTablePagination: {
          root: {
            "&:last-child": {
              padding: "0px 24px 0px 15px",
            },
          },
          toolbar: {
            float: "left",
            padding: "0px",
            color: "#fff",
          },
        },
        MuiPrivateSwitchBase: {
          root: {
            display: "none",
          },
        },
        MUIDataTableToolbar: {
          root: {
            display: "none",
          },
        },
      },
    });

  const tableOptions = {
    selectableRows: "none",
    search: false,
    print: false,
    download: false,
    viewColumns: true,
    filter: true,
    sort: true,
    rowsPerPage: [10],
    rowsPerPageOptions: [10, 20, 30],
    fixedHeader: true,
    fixedHeaderOptions: {
      xAxis: true,
      yAxis: true,
    },
    searchText: searchQuery,
    responsive: "standard",
    textLabels: {
      body: {
        noMatch: apiLoader
          ? APP_CONST.FETCHING_APPLICATION_DETAILS
          : APP_CONST.NO_APPLICATION_FOUND,
      },
    },
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="discovery-main-container">
        <div className="discovery-title mt-5">
          <Box width={isMdDown ? "100%" : "50%"} className="text-center">
            <Typography className="text-container">
              Discover a suitable application for your needs from the entire
              gamut of published apps
            </Typography>
          </Box>
        </div>
        <div className="discovery-searchbox mt-5">
          <InputBase
            className={`styledInputBase ${
              isMdDown ? "styledInputBaseAlignment" : "styledInputBaseAlign"
            }`}
            isMdDown={isMdDown}
            startAdornment={
              <Search
                sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                fontSize="1.8vh"
              />
            }
            value={searchQuery}
            placeholder="Search applications"
            onChange={handleSearchChange}
          />
        </div>
        <div className="discovery-app-table">
          <ThemeProvider theme={getMuiThemeUsage()}>
            <MUIDataTable
              data={tableData}
              columns={columns}
              options={tableOptions}
            />
          </ThemeProvider>
        </div>
        <div
          className={`copyRightSection-discovery mb-4 d-flex justify-center bottom`}
        >
          © 2024 LTIMindtree Limited
        </div>
      </div>
    </>
  );
}

export default Discovery;
