import React, { useState,useEffect,useRef } from "react";
import "./AppModal.css";
import crossIcon from "../../../assets/images/crossIcon.svg";
import efficiencyIcon from "../../../assets/images/efficiency.svg";
import productivityIcon from "../../../assets/images/productivity.svg";
import revenueIcon from "../../../assets/images/revenue.svg";
import { Chip, Grid } from "@mui/material";
import { JsonView } from "react-json-view-lite";
import {
  EFFICIENCY,
  NOT_AVAILABLE,
  REVENUE,
} from "../../../Constants/app.contant";
import { localStorageGetItem } from "../../../utils/Storage/storageUtils";
import APP_CONST from "../../../Constants/app.constant";

const AppModal = (props) => {
  const { data } = props.data;
  data.creditsArray = data.credits.split(",");
  const [apiEndPoint, setApiEndPoint] = useState("");
  const [requestBody, setRequestBody] = useState();
  const [responseBody, setResponseBody] = useState();
  const [showMoreData, setShowMoreData] = useState({
    what: false,
    why: false,
    how: false,
    credits: false,
  });

  const handleClose = () => {
    props.setShowAppModal(!props.showAppModal);
  };

  const handleOpenClick = (event, data) => {
    event.preventDefault();
    if (data[0] === "API") {
      setApiEndPoint(data.appUrl);
      setRequestBody(data.reqStruct);
      setResponseBody(data.respStruct);
    }
  };

  const openExternalApp = (url) => {
    const receiver = window.open(url, "_blank");
    setTimeout(() => {
      receiver?.postMessage(
        JSON.stringify(localStorageGetItem("access_token")),
        url
      );
    }, 5000);
  };

  const renderCredits = () => {
    const length =
      data.creditsArray?.length > 3
        ? showMoreData.credits
          ? data.creditsArray.length
          : 3
        : data.creditsArray.length;
    const filteredCredits = data.creditsArray.slice(0, length);
    return filteredCredits.map((credit, index) => {
      return (
        <>
          <a href={`mailto:${credit.trim()}`} className="creditsData">
            {credit.trim()}
            {index !== data.creditsArray.length - 1 && `,`}
          </a>
          {index !== length - 1 && <br />}
        </>
      );
    });
  };
  const ref = useRef(null);
  const refanchor = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty('--dynamic-color', data.link_color);
    }
    if (refanchor.current) {
      refanchor.current.style.setProperty('--dynamic-font-color', data.link_color);
    }
  }, [data.link_color]);

  function readLessMore(args) {
    return args ? "Read Less" : "Read More";
  }

  function moreData(args, charLength, keyName) {
    return (
      data[args]?.length > charLength && (
        <span
          className="textUnderline"
          onClick={() =>
            setShowMoreData((prev) => ({
              ...prev,
              [keyName]: !prev[keyName],
            }))
          }
        >
          {readLessMore(showMoreData[keyName])}
        </span>
      )
    );
  }

  function dataCredits() {
    return data.credits?.length > 0 ? renderCredits() : APP_CONST.NOT_AVAILABLE;
  }

  const efficiencyProductivityIcon = () => {
    return data?.gain[0]?.toLowerCase() === APP_CONST.EFFICIENCY
      ? efficiencyIcon
      : productivityIcon;
  };
  function dataGain() {
    return (
      data?.gain.length > 0 && (
        <div className="model-desc position-absolute mt-0">
          <div className="model-gain d-flex">
            <img
              className="user-select-none"
              src={`${
                data?.gain[0]?.toLowerCase() === APP_CONST.REVENUE
                  ? revenueIcon
                  : efficiencyProductivityIcon()
              }`}
              alt="Gain icon"
            />
            {data.gain[0]}
          </div>
        </div>
      )
    );
  }

  const dataDescriptionSubString = () => {
    return showMoreData.what
      ? data.description + " "
      : data.description?.substring(0, 112) + "... ";
  };

  function dataDescription() {
    return data.description?.length > 125
      ? dataDescriptionSubString()
      : data.description;
  }

  const dataWhyHowSubstring = (key) => {
    return showMoreData[key]
      ? data[key] + " "
      : data[key]?.substring(0, 112) + "... ";
  };

  const dataWhyHowLength = (key) => {
    return data[key]?.length > 125 ? dataWhyHowSubstring(key) : data[key];
  };

  function dataWhyHow(key) {
    return data[key]?.length > 0
      ? dataWhyHowLength(key)
      : APP_CONST.NOT_AVAILABLE;
  }

  function dataApp() {
    if (data.isAppType) {
      return renderAppLink();
    } else if (data.appLabel === "TalentCyper") {
      return renderTalentCyperLink();
    } else {
      return renderDefaultLink();
    }
  }

  function renderAppLink() {
    const isReqStructEmpty = Object.keys(data.reqStruct).length === 0;
    const className = getClassName();

    if (isReqStructEmpty) {
      return createAnchorTag(data.appUrl, className, "_blank", "noreferrer");
    } else {
      return createAnchorTag(
        "#",
        className,
        "",
        "",
        (e) => handleOpenClick(e, data),
        "modal",
        "#apiModal"
      );
    }
  }

  function renderTalentCyperLink() {
    const className = getClassName();
    const baseUrl = data.baseUrl.split("/?")[0];

    return createAnchorTag(
      "#",
      className,
      "",
      "",
      () => openExternalApp(baseUrl),
      "",
      "",
      baseUrl
    );
  }

  function renderDefaultLink() {
    const className = getClassName();
    const url = `${data.baseUrl}?space=${encodeURIComponent(
      data.space
    )}&hint=${encodeURIComponent(data.hint)}&title=${
      data.appLabel ? data.appLabel : data.name
    }&desc=${encodeURIComponent(data.description)}&userId=${localStorageGetItem(
      "userId"
    )}&spaceName=${data.spaceName}&appId=${data._id}`;

    return createAnchorTag(url, className, "_blank", "noreferrer", handleClose);
  }

  function getClassName() {
    return `source-doc as modal-font-color`;
  }

  function createAnchorTag(
    href,
    className,
    target,
    rel,
    onClick,
    dataToggle,
    dataTarget,
    dataAttr
  ) {
    return (
      <a
        ref={refanchor}
        className={className}
        href={href}
        target={target}
        rel={rel}
        onClick={onClick}
        data-toggle={dataToggle}
        data-target={dataTarget}
        data={dataAttr}
      >
        <span className="launch-app">Launch</span>
      </a>
    );
  }

  function dataCreditsStyle() {
    return data.credits?.length > 0 ? "creditsData " : "";
  }

  const modalClapUpdate = () => {
    props.updateClap();
  }
  return (
    <div className="appModal">
      <div ref={ref}
        className="modal-container modal-background"
      >
        <div className="close-icon">
          <img src={crossIcon} alt="" onClick={handleClose} />
        </div>
        <div className="model-details position-relative">
          <div className="modal-icon">
            <div className="galleryModalIcon">
              <span>{`${data.appLabel}`.substring(0, 2)}</span>
            </div>
            <img src={props.icon} alt="galleryIcon" />
          </div>
          <div className="model-data overflow-auto">
            <div className="model-title">{data.appLabel}</div>
            <div className="model-desc-container">
              <div className="row p-0 m-0">
                <div className="col-6 p-0 m-0">
                  <div className="model-desc">
                    <div className="model-what"> What </div>
                    <span className="model-desc-data">
                      {data.description?.length > 125
                        ? showMoreData.what
                          ? data.description + " "
                          : data.description?.substring(0, 112) + "... "
                        : data.description}
                    </span>
                    {data.description?.length > 125 && (
                      <span
                        className="textUnderline"
                        onClick={() =>
                          setShowMoreData((prev) => ({
                            ...prev,
                            what: !prev.what,
                          }))
                        }
                      >
                        {showMoreData.what ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </div>
                  <div className="model-desc">
                    <div className="model-credits">Credits</div>
                    <span
                      className={`${
                        data.credits?.length > 0 ? "creditsData " : ""
                      }model-desc-data`}
                    >
                      {data.credits?.length > 0
                        ? renderCredits()
                        : NOT_AVAILABLE}
                    </span>
                    {data.creditsArray?.length > 3 && (
                      <span
                        className="textUnderline"
                        onClick={() =>
                          setShowMoreData((prev) => ({
                            ...prev,
                            credits: !prev.credits,
                          }))
                        }
                      >
                        <br />
                        {showMoreData.credits ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-6 p-0 m-0">
                  <div className="model-desc">
                    <div className="model-why"> Why </div>
                    <span className="model-desc-data">
                      {data.why?.length > 0
                        ? data.why?.length > 125
                          ? showMoreData.why
                            ? data.why + " "
                            : data.why?.substring(0, 112) + "... "
                          : data.why
                        : NOT_AVAILABLE}
                    </span>
                    {data.why?.length > 125 && (
                      <span
                        className="textUnderline"
                        onClick={() =>
                          setShowMoreData((prev) => ({
                            ...prev,
                            why: !prev.why,
                          }))
                        }
                      >
                        {showMoreData.why ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </div>
                  <div className="model-desc">
                    <div className="model-how"> How </div>
                    <span className="model-desc-data">
                      {data.how?.length > 0
                        ? data.how?.length > 125
                          ? showMoreData.how
                            ? data.how + " "
                            : data.how?.substring(0, 112) + "... "
                          : data.how
                        : NOT_AVAILABLE}
                    </span>
                    {data.how?.length > 125 && (
                      <span
                        className="textUnderline"
                        onClick={() =>
                          setShowMoreData((prev) => ({
                            ...prev,
                            how: !prev.how,
                          }))
                        }
                      >
                        {showMoreData.how ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="appmodal-clap-tag-section">
              <div className="tags-container mb-2">
                {[...new Set(data.tag)].map((tag) => {
                  return (
                    <Chip label={tag} size="small" className="chip-element" />
                  );
                })}
              </div>
              <div className="clap-icon-modal">
                <img src={props.clapIcon}  onClick={()=>modalClapUpdate()}/>
                <span >{props.clapCount}</span>
              </div>
            </div>
          </div>

          <div className="launch-container position-relative mt-2 d-flex">
            {data?.gain.length > 0 && (
              <div className="model-desc position-absolute mt-0">
                <div className="model-gain d-flex">
                  <img
                    className="user-select-none"
                    src={`${
                      data?.gain[0]?.toLowerCase() === REVENUE
                        ? revenueIcon
                        : data?.gain[0]?.toLowerCase() === EFFICIENCY
                        ? efficiencyIcon
                        : productivityIcon
                    }`}
                    alt="Gain icon"
                  />
                  {data.gain[0]}
                </div>
              </div>
            )}
            <div className="launch-button">
              <Grid
                item
                justifyContent={"flex-start"}
                flexDirection={"column-reverse"}
                display={"flex"}
              >
                {data.isAppType ? (
                  Object.keys(data.reqStruct).length === 0 ? (
                    <a ref={refanchor}
                      className="source-doc modal-font-color"
                      href={data.appUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="launch-app">Launch</span>
                    </a>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a ref={refanchor}
                      className="source-doc modal-font-color"
                      onClick={(e) => handleOpenClick(e, data)}
                      dataToggle="modal"
                      dataTarget="#apiModal"
                    >
                      <span className="launch-app">Launch</span>
                    </a>
                  )
                ) : data.appLabel === "TalentCyper" ? (
                  <a
                    ref={refanchor}
                    className="source-doc as modal-font-color"
                    data={data.baseUrl.split("/?")[0]}
                    onClick={() => openExternalApp(data.baseUrl.split("/?")[0])}
                  >
                    <span className="launch-app">Launch</span>
                  </a>
                ) : (
                  <a ref={refanchor}
                    className="source-doc modal-font-color"
                    href={`${data.baseUrl}?space=${encodeURIComponent(
                      data.space
                    )}&hint=${encodeURIComponent(data.hint)}&title=${
                      data.appLabel ? data.appLabel : data.name
                    }&desc=${encodeURIComponent(
                      data.description
                    )}&userId=${localStorageGetItem("userId")}&spaceName=${
                      data.spaceName
                    }&appId=${data._id}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleClose}
                  >
                    <span className="launch-app">Launch</span>
                  </a>
                )}
              </Grid>
            </div>
          </div>
          <div
            className="modal fade bd-example-modal-lg"
            id="apiModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="apiModalTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="apiModalTitle">
                    API Details
                  </h5>
                  <div
                    className="close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></div>
                </div>
                <div className="pageview-body">
                  <label className="input-label">API End Point</label>{" "}
                  <div className="form-data">
                    <input
                      className="form-control"
                      placeholder="Enter chat name"
                      value={apiEndPoint}
                    ></input>
                  </div>
                  <label className="input-label">Request Body</label>
                  <div className="form-data json-viewer">
                    <JsonView
                      src={requestBody}
                      theme="summerfruit:inverted"
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={false}
                      className="custom-json-view"
                    />
                  </div>
                  <label className="input-label">Response Body</label>
                  <div className="form-data json-viewer">
                    <JsonView
                      src={responseBody}
                      theme="summerfruit:inverted"
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={false}
                      className="custom-json-view"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppModal;
