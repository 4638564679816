/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { StyledInputBase } from "./style";
import AppsCard from "../Card";
import { Search } from "@mui/icons-material";
import "./navlanding.css";
import { useNavigate } from "react-router-dom";
import { getAllNavigatorApps } from "../../Services/landing.service";
import Loader from "../Loader";
import { APP, HOME, LOGIN } from "../../Constants/api.constant";
import { logGAPageView } from "../../utils/Helper";
import Header from "../LandingPage/Header";
import Sidebar from "../Sidebar/Sidebar";
import { localStorageGetItem } from "../../../src/utils/Storage/storageUtils";
import refreshIcon from "../../assets/images/refresh-icon.svg";

const Navlanding = () => {
  logGAPageView(APP, "App Landing");

  const [space, setSpace] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [categoryName, setCategoryName] = useState("Spotlight");
  const [filteredGalleryList, setFilteredGalleryList] = useState([]);
  const [displayFilterList, setDisplayFilterList] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const AppsColor = ["#F3F3F3"];

  const AppLinkColor = [
    "#FFFFFF",
    "#E8F6FF",
    "#FFFFFF",
    "#FEF1F1",
    "#F9F2F2",
    "#EBFFE9",
    "#FFFAE8",
    "#F9F2F2",
  ];
  if (tab !== 1) {
    navigate(HOME, {
      state: {
        tabValue: tab,
      },
    });
  }
  useEffect(() => {
    document.title = "Canvas AI - Gallery";
    setWindowHeight(window.innerHeight - 350);
    getApps();
  }, []);

  const getWindowDimensions = () => {
    const { innerHeight: height } = window;
    return {
      height,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      const { height } = getWindowDimensions();
      setWindowHeight(height - 350);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getApps = async () => {
    checkAppAccess();
    setIsLoading(true);
    getAllNavigatorApps()
      .then((res) => {
        if (Array.isArray(res.data)) {
          const result = res.data.map((item) => {
            return {
              ...item,
            };
          });
          const coloredResponse = distributeColor(result);
          setSpace(coloredResponse);
          setSelectedNavApps(coloredResponse);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSelectedNavApps(space);
    setSearchQuery("");
  }, [categoryName]);

  const setSelectedNavApps = (navApps) => {
    const filteredNavApps =
      categoryName === "All Apps"
        ? navApps
        : navApps
            .sort((a, b) => a?.name?.localeCompare(b.name))
            .filter((item) => {
              return (
                item.name.includes(categoryName?.toLowerCase()) ||
                item?.tag.some((tag) =>
                  tag?.toLowerCase()?.includes(categoryName?.toLowerCase())
                ) ||
                item.appLabel.includes(categoryName?.toLowerCase())
              );
            });
    setFilteredGalleryList(filteredNavApps);
    setDisplayFilterList(filteredNavApps);
  };
  const matchesQuery = (obj, query) => {
    const lowerCaseQuery = query.toLowerCase();
    if (obj.name.toLowerCase().includes(lowerCaseQuery)) {
      return true;
    }
    if (
      obj.tag.some((tagItem) => tagItem.toLowerCase().includes(lowerCaseQuery))
    ) {
      return true;
    }
    if (obj.appLabel.toLowerCase().includes(lowerCaseQuery)) {
      return true;
    }
    return false;
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query === "") {
      setDisplayFilterList(filteredGalleryList);
    } else {
      setDisplayFilterList(
        filteredGalleryList.filter((item) => matchesQuery(item, query))
      );
    }
  };

  const distributeColor = (arr) => {
    let arrData = arr;
    for (let i = 0; i < arrData.length; i++) {
      arrData[i].bg_color = AppsColor[0];
      arrData[i].link_color = AppLinkColor[i % 8];
    }
    return arrData;
  };

  const checkAppAccess = () => {
    const accessToken = localStorageGetItem("access_token");
    if (typeof accessToken === "undefined" && accessToken === null)
      navigate(LOGIN);
  };

  return (
    <>
      <Header tab={tab} setTab={setTab} />
      {isLoading && <Loader />}
      <div className="vh-100 overflow-auto">
        <Grid container xs={12}>
          <Grid item xs={1} className="centered-grid">
            <Sidebar
              setCategoryName={setCategoryName}
            />
          </Grid>
          <Grid item xs={11}>
            <Grid container xs={12} direction="row">
              <Grid container className="headerGrid">
                <Grid item xs={12} className="custom-grid">
                  <Box
                    width={isMdDown ? "100%" : "50%"}
                    className="text-center"
                  >
                    <Typography className="text-container">
                      AI powered Navigator applications curated for you and your
                      unit
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} className="headerGrid d-flex justify-center">
                <StyledInputBase
                  isMdDown={isMdDown}
                  startAdornment={
                    <Search
                      sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                      fontSize="1.8vh"
                    />
                  }
                  value={searchQuery}
                  placeholder="Search applications"
                  onChange={handleSearchChange}
                />
                <div className="refresh-button" onClick={() => getApps()}>
                  <img className="refresh" src={refreshIcon} alt="refresh" />
                  <span>Refresh</span>
                </div>
              </Grid>
              <Grid
                container
                className={`gridContainer ${
                  displayFilterList?.length === 0 ? "empty" : ""
                } ${displayFilterList?.length > 0 ? "normal" : "center"}`}
                xs={12}
                spacing={3}
                marginTop={"10px"}
                paddingLeft={"20px"}
                paddingBottom={"80px"}
                minHeight={displayFilterList?.length === 0 && windowHeight}
                justifyContent={
                  displayFilterList?.length > 0 ? "flex-start" : "center"
                }
                alignItems={displayFilterList?.length > 0 ? "normal" : "center"}
              >
                <>
                  {displayFilterList?.length > 0 ? (
                    <>
                      {displayFilterList?.map((data, index) => (
                        <AppsCard data={data} index={index} />
                      ))}
                    </>
                  ) : isLoading ? (
                    <></>
                  ) : (
                    <>
                      <div className="align-center justify-center flex-column text-center text-default">
                        <p className="empty-appList">
                          There are no Navigator Apps
                        </p>
                      </div>
                    </>
                  )}
                  <div
                    className={`copyRightSection d-flex justify-center ${
                      displayFilterList?.length > 0 ? "normal" : "bottom"
                    }`}
                  >
                    © 2024 LTIMindtree Limited
                  </div>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Navlanding;
